<template>
  <ul class="footer-links">
    <li v-for="link in footerLinks" :key="link.url">
      <a :href="link.url" target="_blank">{{ link.label }}</a>
    </li>
    <li>
      <a :href="$t('privacyPolicy.url')" target="_blank">{{ $t('privacyPolicy.label') }}</a>
    </li>
  </ul>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'FooterLinks',
    computed: {
      ...mapGetters('onboarding', ['footerLinks']),
    },
  }
</script>

<style lang="stylus">
  .footer-links
    margin 0
    padding 16px
    font-size .9em
    text-align center

    li
      display inline-block
      margin-left 16px
      position relative

      &::before
        content ''
        width 6px
        height 6px
        background var(--brand-color1)
        position absolute
        top 6px
        left -11px
        border-radius 50%
      &:first-child
        margin-left 0

        &::before
          content none

      a, a:active, a:visited, a:focus
        text-decoration none
        color var(--brand-color1)

      a:hover
        text-decoration underline
</style>
